/**
 * Parental Controls' PIN Popup plugin: check PIN
 * Updated date: 2018.11.20
 * Updated items:
 * 1.rename restart to reset
 * 2.change 5 cookies to 1 localstorage & 1 session cookie
 *
 * Updated date:20181213
 * Updated items:
 * 1.[optimize]: adjust noAccessHandler position
 *
 * Updated date:20181221
 * Updated items:
 * 1.[Enhance] return tv-rating after processDefaultTVRating
 *
 * * Updated date:20190114
 * Updated items:
 * 1.[Bug] not need P.C. if user not logged In. Related to OSN-510
 *
 *
 * Updated date:20190228
 * Updated items:
 * https://jira.neulion.com/browse/UNI-3280
 *
 * Updated date:20190301
 * Updated items:
 * 1.[Enhance] provide class static function 'programTVRating' to get tvRating by MAP. Related to OSN-339
 *
 * Updated date:20190311
 * Updated items:
 * https://jira.neulion.com/browse/UNI-4121 Users types PIN quickly and digits get stuck in the last PIN box
 *
 * Updated date:20190715
 * Updated items:
 * https://jira.neulion.com/browse/UNI-4600 IE 11 - Parental controls cursor isn't aligned at first
 */
class ParentalControlsPopup {
	pinItemTemplate = '<input type="text" class="input-pin-item" type="number" pattern="[0-9]*" name="pin{index}" maxlength="1" id="pin{index}"/>';

	defaultConfig = {
		isLocked: false,
		container: ".js-parentalControls-lock",
		pinInputContainerId: 'pinInputList',
		digits: 0,
		attempts: -1,
		pcpin: null,
		pinSuccessHandler: null,
		cancelHandler: null,
		pinInputCompleteCallback: null,
	}

	constructor(options)
	{
		this.options = {...this.defaultConfig, ...options};
		this.localStorageKey = 'pc';
		this.storeConfigKey ='config';
		this.storeProfileKey ='user';
		this.errorTimes = isNaN(parseInt(this.getStore('user').errorTimes)) ? 0 : parseInt(this.getStore('user').errorTimes);
	}

	init = function () {
		const that = this;
		$("#program_error_pin").removeClass('visibility-visible');
		this.showOverlayForMobile(true);
		this.showOverlay(this.options.container);
		if (this.options.isLocked)
		{
			$('#js-pc-locked').show();
			$('#js-pc-pin').hide();

		}
		else
		{
			$('#js-pc-locked').hide();
			$('#js-pc-pin').show();

			let digits = parseInt(this.options.digits);
			let pinInputHtml = '';

			for (let index = 1; index <= digits; index++)
			{
				pinInputHtml += this.pinItemTemplate.replace(/\{index\}/gi, index);
			}

			$('#' + this.options.pinInputContainerId).html(pinInputHtml);
			$("#program_error_pin").removeClass('visibility-visible');
			$("input[name^='pin']").each(function () {
				$(this).val('');
			});
			this.bindKeydown();
			setTimeout(function () {
				$('#' + that.options.pinInputContainerId).children('.input-pin-item:first').focus();
			}, 500);
		}
		this.bindClose();

	}


	bindKeydown = function () {
		const owner = this;
		$('#' + owner.options.pinInputContainerId).find('.input-pin-item').each(function () {
			$(this).unbind('keydown').bind('keydown', function (e) {
				// arrows
				if (e.which == 37 || e.which == 38) return step(this, 0);
				if (e.which == 39 || e.which == 40) return step(this, 1);
				// backspace
				if (e.which == 8) return step(this, 0, 1);
			});

			$(this).unbind('input').bind('input', function (e) {
				let value = $(this).val().trim();
				if (value == '' || isNaN(value))
				{
					$(this).val('');
					return false;
				}
				step(this, 1);
				owner.inputKeyDownHandle();
			});
		});

		let step = function (target, dir, clr) {
			let $target = $(target);
			if (clr) $target.val('');
			let $next = $target[['prev', 'next'][dir]]();
			if (!$next.length)
			{
				let $siblings = $target.siblings()
				if (dir === 1)
				{
					$next = $siblings.eq(0);
				}
				else if(dir === 0)
				{
					$next = $siblings.eq($siblings.length - 1);
				}
				else
				{
					$next = $target;
				}
			};
			setTimeout(function () {
				$next.select();
			}, 0);
			return false;
		}
	}

	bindClose = function () {
		const owner = this;
		$(owner.options.container + ' .js-close').unbind('click').bind('click', function () {
			owner.hideKeyboard();
			owner.hideOverlay($(owner.options.container));
			owner.showOverlayForMobile(false);
			owner.options.cancelHandler && owner.options.cancelHandler();
		})
	}

	inputKeyDownHandle = function () {
		const owner = this;
		let len = 0;
		let val = '';
		$("input[name^='pin']").each(function () {
			if ($(this).val().length >= 1)
			{
				len++;
				val += $(this).val().toString();
			}
		});
		if (len == owner.options.digits)
		{
			owner.checkPIN(val);
		}
		else
		{
			$("#program_error_pin").removeClass('visibility-visible');
		}
	}

	checkPIN = function (val) {
		const that=this;
		if (this.options.pcpin && this.options.pcpin.toString() === val)
		{
			this.hideOverlay($(this.options.container));
			this.showOverlayForMobile(false);
			$("#program_error_pin").removeClass('visibility-visible');
			var user = this.getStore('user') ? this.getStore('user') : {};
			var newuser = {...user, access: '1'};
			this.setStore('user', newuser);
			this.options.pinSuccessHandler && this.options.pinSuccessHandler();
			this.hideKeyboard();
			this.options.pinInputCompleteCallback ? this.options.pinInputCompleteCallback('success') : ga && ga('send', 'event', { eventCategory: 'Engagement', eventAction: 'Entered PIN', eventLabel: 'success' });
		}
		else
		{
			this.errorTimes++;
			var user = this.getStore('user') ? this.getStore('user') : {};
			var newuser = {...user, errorTimes: this.errorTimes};
			this.setStore('user', newuser);

			if (parseInt(this.options.attempts) >= 0 && parseInt(this.errorTimes) >= this.options.attempts)
			{
				this.hideKeyboard();
				var user = this.getStore('user') ? this.getStore('user') : {};
				var newuser = {...user, access: '0'};
				this.setStore('user', newuser);
				$('#js-pc-locked').show();
				$('#js-pc-pin').hide();
			}
			else
			{
				$("#program_error_pin").addClass('visibility-visible');

				$("input[name^='pin']").each(function () {
					$(this).val('');
				});

				setTimeout(function () {
					$('#' + that.options.pinInputContainerId).children('.input-pin-item:first').focus();
				}, 0);

			}

			this.options.pinInputCompleteCallback ? this.options.pinInputCompleteCallback('fail'): ga && ga('send', 'event', { eventCategory: 'Engagement', eventAction: 'Entered PIN', eventLabel: 'fail' });
		}
	}

	showOverlayForMobile = function (showFlag) {
		if (/iPad|iPhone|iPod/i.test(navigator.userAgent))
		{
			let bodyEl = document.body;
			let $bindTarget = $(".js-overlay");
			let bodyTop = 0;
			if (/iPhone/i.test(navigator.userAgent))
			{
				$bindTarget = $(".js-parentalControls-lock");
			}
			if (showFlag)
			{
				//Step 1: change position from 'fixed' to 'absolute'
				$('body').addClass('is-pin-overlay');
				//Step 2: fix the modal's distance to top
				bodyTop = window.scrollY;
				bodyEl.style.position = 'relative';
				bodyEl.style.top = bodyTop + 'px';
				document.documentElement.style.overflow = 'hidden';
				//Step 3: Forbidden the scroll event
				$bindTarget.bind('touchmove', function (e) {
					e.stopPropagation();
					e.preventDefault();
				})
			}
			else
			{
				$('body').removeClass('is-pin-overlay');
				bodyEl.style.position = '';
				bodyEl.style.top = '';
				document.documentElement.style.overflow = '';
				window.scrollTo(0, bodyTop);// return to scroll position
				$bindTarget.unbind('touchmove');
			}
		}
	}

	/*common function*/

	showOverlay(dom)
	{
		$(".js-modal").removeClass("is-open");

		$(".js-modal-wrap").height($(dom).height());
		$(window).resize(function () {
			$(".js-modal-wrap").height($(dom).height());
		});

		if (!$("body").hasClass("is-overlay-open"))
		{
			$("body").addClass("is-overlay-open");
		}
		if (!$(".js-overlay").hasClass("is-overlay-open"))
		{
			$(".js-overlay").addClass("is-overlay-open");
		}
		$(dom).addClass("is-open");

	}

	hideOverlay(dom)
	{
		dom.addClass("is-hidden");
		setTimeout(function () {
			removeModalClass(dom)
		}, 500);

		let removeModalClass = function (dom) {
			$(".js-modal-wrap").height = 0;
			$(".js-overlay").removeClass("is-overlay-open");
			$("body").removeClass("is-overlay-open");
			if (dom.hasClass("is-hidden"))
			{
				dom.removeClass("is-hidden");
			}
			dom.removeClass("is-open");
		}
	}

	hideKeyboard()
	{
		setTimeout(()=>{
			document.activeElement.blur();
			$("#js-pc-pin input").blur();
		}, 0)
	}

	setStore(key, value, mins)
	{
		const oldpc = JSON.parse(window.localStorage.getItem(this.localStorageKey)) || {};
		let newObj = {};
		newObj[key] = value;
		if (mins)
		{
			var date = new Date();
			date.setTime(date.getTime() + (mins * 60 * 1000));
			newObj[key]['expires'] = date.toGMTString();
		}
		const newpc = {...oldpc, ...newObj};
		window.localStorage.setItem(this.localStorageKey, JSON.stringify(newpc));
	}

	getStore(key)
	{
		var pc = JSON.parse(window.localStorage.getItem(this.localStorageKey)) || {};
		return pc && pc[key] ? pc[key] : null;
	}
}


/**
 * Parental Controls Plugin: check program/channel's tvRating vs ps
 * dependency: no dependencies
 */
(function () {
	var ParentalControls = function () {
		this.feedUrl = window.CDN_CONFIG ? window.CDN_CONFIG + "parentalcontrols.js" : window.config.CDN_CONFIG + "parentalcontrols.js";
		this.configExpiresTime = 5;
		this.profileExpiresTime = 5;
		this.localStorageKey = 'pc';
		this.storeConfigKey ='config';
		this.storeProfileKey ='user';
	}

	// Load config data
	ParentalControls.prototype.loadConfigData = function (callback) {
		var owner = this;
		getJSON(owner.feedUrl, function (configData) {
			const config = configData.pin || '';
			owner.setStore(owner.storeConfigKey,config, owner.configExpiresTime);
			callback && callback(config);
		}, function () {
			callback && callback({});
		});
	}

	// Load ps data
	ParentalControls.prototype.loadProfileData = function (callback) {
		var owner = this;
		if (window.enablePs || (window.project && window.project.enablePs))
		{
			var timeout = 3000, failed = false;
			var iid = window.setTimeout(function () {
				failed = true;
				callback && callback({});
			}, timeout);
			window.ps && window.ps.profile.get(null, function (psData) {
				if (!failed)
				{
					window.clearTimeout(iid);
					const {pcpin, pcratings} = psData;
					const storeProfile = owner.getStore(owner.storeProfileKey);
					const {pin, ratings, access, errorTimes} = storeProfile || {
						pin: '',
						ratings: '',
						access: '',
						errorTimes: ''
					};
					let newpc = {
						pin: pcpin,
						ratings: pcratings,
						access: access,
						errorTimes: errorTimes
					}
					if (pin != pcpin || ratings != pcratings || getCookie('pc') == null)
					{//user update P.C. info
						newpc['access'] = '';
						newpc['errorTimes'] = 0;
						newpc['pin'] = pcpin;
						newpc['ratings'] = pcratings;
					}
					owner.setStore(owner.storeProfileKey,newpc,owner.profileExpiresTime);
					if (getCookie('pc') == null)
					{
						setCookie('pc', 1);
					}
					callback && callback(newpc);
				}
			});
		}
		else
		{
			callback && callback({});
		}
	}

	ParentalControls.prototype.setStore = function (key, value, mins) {
		const oldpc = JSON.parse(window.localStorage.getItem(this.localStorageKey)) || {};
		let newObj = {};
		newObj[key] = value;
		if (mins)
		{
			var date = new Date();
			date.setTime(date.getTime() + (mins * 60 * 1000));
			newObj[key]['expires'] = date.toGMTString();
		}
		const newpc = {...oldpc, ...newObj};
		window.localStorage.setItem(this.localStorageKey, JSON.stringify(newpc));
	}

	ParentalControls.prototype.getStore = function (key) {
		var pc = JSON.parse(window.localStorage.getItem(this.localStorageKey)) || {};
		return pc && pc[key] ? pc[key] : null;
	}

	ParentalControls.prototype.isStoreExpires = function (key) {
		var valueObj = this.getStore(key);
		if (valueObj == null)
		{
			return true;
		}
		else
		{
			var expires = valueObj['expires'];
			return (new Date().getTime() - new Date(expires).getTime()) > 0 ? true : false;
		}
	}

	// Get user's setting data from ps or cookies
	ParentalControls.prototype.getProfile = function (callback) {
		var isSessionOut = getCookie('pc') ? false : true;
		var profileExpires = this.isStoreExpires(this.storeProfileKey);
		var profile = this.getStore(this.storeProfileKey);
		var authData = {};
		if (!isSessionOut && !profileExpires && profile)
		{
			authData = {
				pin: profile.pin || '',
				ratings: profile.ratings || '',
				access: profile.access || '',
				errorTimes: profile.errorTimes || '',
			}
			callback && callback(authData);
		}
		else
		{
			this.loadProfileData(function (data) {
				callback && callback(data);
			})
		}
	}

	// Get Config data from cdn or cookies
	ParentalControls.prototype.getConfig = function (callback) {
		var configData = {};
		var configExpires = this.isStoreExpires(this.storeConfigKey);
		var config = this.getStore(this.storeConfigKey);
		if (!configExpires && config)
		{
			var digits = config.digits ? config.digits : 0;
			var attempts = config.attempts ? config.attempts : 0;
			configData = {
				digits: digits,
				attempts: attempts
			}
			callback && callback(configData);
		}
		else
		{
			this.loadConfigData(function (data) {
				var digits = data.digits;
				var attempts = data.attempts;
				configData = {
					digits: digits,
					attempts: attempts
				}
				callback && callback(configData);
			})
		}
	}

	ParentalControls.prototype.checkAccess = function (tv_rating, callback) {

		let tvRating = ParentalControls.getProgramTVRating(tv_rating);
		this.getProfile(function (pcData) {
			let access = pcData.access;
			let pin = pcData.pin;
			let ratings = pcData.ratings;
			let ratingList = (ratings || '').split(',').map(function (item) {
				return item.toLowerCase();
			});
			if (tvRating == "" || pin == "" || ratingList.length == 0 || access == "1" || $.inArray(tvRating.toLowerCase(), ratingList) === -1)
			{
				callback && callback(false, pcData, tvRating);
			}
			else
			{
				callback && callback(true, pcData, tvRating);
			}
		});
	}

	ParentalControls.prototype.showPINModal = function (profileData, successCallback, cancelHandler) {
		this.getConfig(function (configData) {
			let pc_pin = profileData.pin;
			let pc_ratings = profileData.ratings;
			let pc_access = profileData.access;
			let digits = configData.digits;
			let attempts = configData.attempts;

			if (pc_access == "0")
			{
				let pinModal = new ParentalControlsPopup({
					isLocked: true,
					container: ".js-parentalControls-lock",
					cancelHandler: cancelHandler
				});
				pinModal.init();
			}
			else
			{
				if (pc_pin && pc_ratings && digits && attempts)
				{
					let pinModal = new ParentalControlsPopup({
						container: ".js-parentalControls-lock",
						digits: digits,
						attempts: attempts,
						pcpin: pc_pin || '',
						pinSuccessHandler: successCallback,
						cancelHandler: cancelHandler,
						pinInputCompleteCallback: window.pinInputCompleteCallback,
					});
					pinModal.init();
				}
				else
				{
					successCallback && successCallback();
				}
			}
		});
	}

	ParentalControls.prototype.check = function (tvRating, pinSuccessHandler, pinCancelHandler, noAccessHandler,) {
		var that = this;
		if ((window.isLoggedIn || (window.client && window.client.isLoggedIn)) && (window.enableParentalControls || (window.project && window.project.enableParentalControls)))
		{
			that.checkAccess(tvRating, (hasPC, profileData, tvRating) => {
				if (hasPC)
				{
					noAccessHandler && noAccessHandler({tvRating: tvRating});
					that.showPINModal(profileData, pinSuccessHandler, pinCancelHandler);
				}
				else
				{
					pinSuccessHandler({tvRating: tvRating});
				}
			});
		}
		else
		{
			pinSuccessHandler();
		}
	}

	// Clear cookie info
	ParentalControls.prototype.reset = function () {
		if (window.enableParentalControls || (window.project && window.project.enableParentalControls))
		{
			setCookie('pc', '', -1);
		}
	}

	// // Get default tvRating by MAP config
	ParentalControls.getProgramTVRating = function (tvRating) {
		let rating = (tvRating || '').toString().toUpperCase();
		if (window.TV_RATING_MAP[rating])
		{
			return window.TV_RATING_MAP[rating];
		}
		else
		{
			return rating;
		}
	}

	//---------------help functions------------------------------
	function getJSON(url, callback, errCallback)
	{
		var request = new XMLHttpRequest();
		request.open('GET', url, true);
		if (window.withCredentials)
		{
			request.withCredentials = true;
		}
		request.onload = function () {
			if (request.status >= 200 && request.status < 400)
			{
				// Success!
				var data = JSON.parse(request.responseText);
				if (callback) callback(data);
			}
			else
			{
				// We reached our target server, but it returned an error
				errCallback && errCallback();
			}
		};

		request.onerror = function () {
			// There was a connection error of some sort
			if (errCallback) errCallback();
		};

		request.send();
	}

	function getCookie(name)
	{
		if (document.cookie.length > 0)
		{
			var startIdx, endIdx;
			startIdx = document.cookie.indexOf(name + "=");
			if (startIdx != -1)
			{
				startIdx = startIdx + name.length + 1;
				endIdx = document.cookie.indexOf(";", startIdx);
				if (endIdx == -1)
				{
					endIdx = document.cookie.length;
				}
				return unescape(document.cookie.substring(startIdx, endIdx));
			}
		}
		return null;
	}

	function setCookie(name, value, mins)
	{
		var expires;
		if (mins)
		{
			var date = new Date();
			date.setTime(date.getTime() + (mins * 60 * 1000));
			expires = "; expires=" + date.toGMTString();
		}
		else
		{
			expires = "";
		}
		document.cookie = name + "=" + value + expires + "; path=/";
	}

	window.ParentalControls = function () {
		return function (options) {
			var myParentalControls = new ParentalControls(options);
			this.check = function (tvRating, successHandler, cancelHandler, noAccessHandler) {
				myParentalControls.check(tvRating, successHandler, cancelHandler, noAccessHandler);
			};
			this.reset = function () {
				myParentalControls.reset();
			}
		}
	}();

	window.ParentalControls.showTVRating = function (autoReplace = false, tvRating) {
		if (autoReplace)
		{
			$("[data-role='tvRating']").each(function () {
				const tvRating = $(this).data('tvrating');
				const programTVRating = ParentalControls.getProgramTVRating(tvRating);
				$(this).html(programTVRating);
			})
		}
		else
		{
			return ParentalControls.getProgramTVRating(tvRating);
		}
	}

})(window);
